import React, { useState, useEffect } from "react";
import "./settings.css";
import Card from "../../images/card4.jpg";
import Close from "../../images/closewhite.png";
import Modal from "react-modal";
import Success from "../../images/approved.png";
import { KEY } from "../../api";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "754px",
    border: "1px solid #C060F1",
    borderRadius: "19px",
  },
};
Modal.setAppElement("#root");

const Notification = () => {
  const [post, setPost] = useState([]);
  const [postId, setPostId] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  let subtitle;
  function afterOpenModal() {
    subtitle.style.color = "#fff";
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    handleSubmitForModal();
  }

  useEffect(() => {
    handleSubmit();
  }, []);
  const handleSubmit = async () => {
    try {
      const response = await KEY.get(
        "/notification/getAll",

        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then((response) => {
        setIsOpen(true);
        setPost(response?.data);
        setPostId(response?.data?.isRead);
      });
    } catch (error) {
      console.error("Error logout:", error);
    }
  };
  const handleSubmitForModal = async (id) => {
    try {
      const response = await KEY.post(
        "/notification",
        { id: id },
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error logout:", error);
    }
  };

  if (!post) return null;
  return (
    post && (
      <div className="notification">
        {[...post]?.slice()?.reverse()?.map((item, index) => {
          return (
            <div key={item?.id}>
              <div className="notificationBlock">
                <img className="notificationImg" src={Card} alt="image" />
                <div className="notificationText">
                  <h3>{item?.title}</h3>
                  <p>{item?.message}</p>
                  {item?.isRead ? null : (
                    <Modal
                      isOpen={modalIsOpen}
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="changeEmail">
                        <button className="closebtn" onClick={closeModal}>
                          <img
                            src={Close}
                            alt="close"
                            onClick={() => handleSubmitForModal(item?.id)}
                          />
                        </button>
                        <div
                          className="notificationModal"
                          ref={(_subtitle) => (subtitle = _subtitle)}
                        >
                          <img src={Success} alt="image" />
                          <p>{item?.message}</p>
                        </div>
                      </div>
                    </Modal>
                  )}
                </div>
              </div>
              <div className="notificationDivider"></div>{" "}
            </div>
          );
        })}

        {/* <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="changeEmail">
            <button className="closebtn" onClick={closeModal}>
              <img src={Close} alt="close" />
            </button>
            <div
              className="notificationModal"
              ref={(_subtitle) => (subtitle = _subtitle)}
            >
              <img src={Success} alt="image" />
              <p>{postId?.message}</p>
            </div>
          </div>
        </Modal> */}
      </div>
    )
  );
};

export default Notification;
