import React, { useRef, useState, useEffect } from "react";
import "./loadvideo.css";
import Down from "../../images/down.png";
import Format from "../../images/format.png";
import Length from "../../images/length.png";
import File from "../../images/file.png";
import Resolution from "../../images/resolution.png";
import Close from "../../images/close.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PriceIcon from "../../images/price.png";

const LoadVideo = () => {
  const [file, setFile] = useState(null);
  const [showbtn, setShowBtn] = useState(false);
  const [showbtn2, setShowBtn2] = useState(false);
  const [showbtn3, setShowBtn3] = useState(false);
  const [fileData, setFileData] = useState(null);
  const { order } = useParams();
  const uploadRef = useRef();
  const statusRef = useRef();
  const loadTotalRef = useRef();
  const progressRef = useRef();
  const loadvideoRef = useRef();
  const loadwidthRef = useRef();
  const navigate = useNavigate();
  const [page, setPage] = useState("");

  const postItem = () => {
    axios
      .get(`https://testarmenia.site:8443/api/leds?ledId=${order}`, {
        headers: {
          "Accept-Language": "en",
          "Content-Type": "application/x-www-form-urlencoded",
          // "X-XSRF-TOKEN": cookies.get('XSRF-TOKEN')
        },
        withCredentials: true,
      })
      .then((response) => {
        setPage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    postItem();
  }, [order]);
 

  const clickhandler = () => {
    loadwidthRef.current.innerHTML = "";
    loadvideoRef.current.innerHTML = "";
    loadTotalRef.current.innerHTML = "";
    setFile(false);
    setShowBtn3(false);
    setShowBtn(false);
    setShowBtn2(false);
  };

  const [progress, setProgress] = useState(0);
  const UploadFile = (e) => {
    const file = uploadRef.current.files[0];

    setFile(URL.createObjectURL(file));

    setFileData(e.target.files[0]);

    const progres = new FileReader();
    progres.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setProgress(percentComplete);
      }
    };

    // Reset progress and read the file
    progres.onloadstart = () => setProgress(0);
    progres.onloadend = () => setProgress(100);

    progres.readAsDataURL(file); // Simulate file reading

    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.total / 1048576 > 50) {
        loadTotalRef.current.innerHTML = `The size is ${(
          e.total / 1048576
        )?.toFixed(2)} mb it exceeds the 50mb limit`;
      } else {
        setShowBtn3(true);
      }

      var media = new Audio(reader.result);

      const videoUrl = URL.createObjectURL(file);
      const video = document.createElement("video");

      video.src = videoUrl;

      video.addEventListener("loadedmetadata", () => {
        const width = video?.videoWidth;
        const height = video?.videoHeight;
        const wit = page?.size?.slice(0, 4) / 1;
        const hig = page?.size?.slice(5) / 1;
        // Display video width

        if (width === wit && height === hig) {
          setShowBtn(true);
        } else {
         
          loadwidthRef.current.innerHTML = `Your Video is: ${width}x${height}pixels (Resolution: ${page?.size} pixels)`;
        }

        // Clean up the object URL
        URL.revokeObjectURL(videoUrl);
      });

      media.onloadedmetadata = () => {
        const resultduration = media.duration;

        if (resultduration > page?.duration) {
          loadvideoRef.current.innerHTML = `Duration must be ${
            page?.duration
          }s , your video is ${resultduration?.toFixed(1)}s`;
        } else {
          setShowBtn2(true);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  // const ProgressHandler = (e) => {
  //   if (e.total / 1048576 > 50) {
  //     loadTotalRef.current.innerHTML = `The size is ${(
  //       e.total / 1048576
  //     )?.toFixed(2)} mb it exceeds the 50mb limit`;
  //   } else {
  //     setShowBtn3(true);
  //   }

  //   var percent = (e.loaded / e.total) * 100;
  //   progressRef.current.value = Math.round(percent);
  //   statusRef.current.innerHTML = Math.round(percent) + "% ";
  // };

  // const SuccessHandler = (e) => {
  //   statusRef.current.innerHTML = e.target.responseText;
  //   // progressRef.current.value = 0;
  // };

  // const ErrorHandler = () => {
  //   statusRef.current.innerHTML = "upload failed!!";
  // };
  // const AbortHandler = () => {
  //   statusRef.current.innerHTML = "upload aborted!!";
  // };

  const submitForApproval = () => {
    if (!fileData) {
      alert("No file data to submit!");
      return;
    }
    let data = { ledId: order, video: fileData };

    // console.log(fileData);

    axios
      .post(`https://testarmenia.site:8443/api/order`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
        // alert(response.data.message || "Submitted successfully!");
        navigate("/received");
      })
      .catch((error) => {
        // alert(error.response?.data?.message || "Submission failed!");
      });
  };

  return (
    <div className="videoContent">
      <div className="container">
        <div className="loadVideo">
          <h1>Upload Your Video</h1>
          <div className="loadVideoTab">
            <div className="uploadbuttns">
              <span className="checked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext"> Upload video</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">2</span>{" "}
              <span className="loadVideotext">Confiramtion</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">3</span>
              <span className="loadVideotext">Completes Payment</span>
            </div>
          </div>

          <div className="loadVideoContent">
            <p>
              {" "}
              <img src={Format} alt="image" />
              Format: MP4 (or other supported formats)
            </p>
            <p>
              {" "}
              <img src={Length} alt="image" />
              Length: Maximum {page?.duration} seconds
            </p>
            <p>
              {" "}
              <img src={File} alt="image" />
              Max File Size: 50 MB
            </p>
            <p>
              {" "}
              <img src={Resolution} alt="image" />
              Resolution: {page?.size?.slice(0, 4)}x{page?.size?.slice(5)}{" "}
              pixels
            </p>

            <ul>
              <li>Video must be clear and well-lit.</li>
              <li>Must be appropriate for all audiences</li>
              <li>No excessive effects or distracting branding.</li>
              <li>Ensure clarity and avoid copyrighted content.</li>
              <li>All legal documents.
                {/* <Link><img src={PriceIcon} alt="image"/></Link>  */}
                <Link  target='_blank' to='http://ibconsulting.ae/'> Any questions?</Link></li>
            </ul>
            <h3>Upload Video</h3>
            <div className="imgLoad">
              <div className="chooseFile">
                {file ? (
                  <div className="uploadedVideo">
                    <img src={Format} alt="upload" />

                    <img
                      src={Close}
                      className="imgClose"
                      onClick={clickhandler}
                      alt="close"
                    />
                  </div>
                ) : (
                  <>
                    <form>
                      <input
                        type="file"
                        ref={uploadRef}
                        onChange={UploadFile}
                        accept="video/*"
                      />
                    </form>
                    <div className="choosebtn">
                      <button>Choose File</button>

                      <span>No File chosen </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {file ? (
              <div>
                {progress > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        height: "9px",
                        maxWidth: "827px",
                        width: "100%",
                        backgroundColor: "#e0e0df",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: `${progress}%`,
                          backgroundColor: "#c060f1",
                          textAlign: "center",
                          lineHeight: "25px",
                        }}
                      ></div>
                    </div>
                    <p>{progress}%</p>
                  </div>
                )}
                {/* <label>
                  <progress ref={progressRef} value="0" max="100" />
                </label> */}
                <p ref={statusRef}></p>
              </div>
            ) : null}

            {file ? (
              <div className="buttomLink">
                {showbtn && showbtn2 && showbtn3 ? (
                  <Link
                    // to={`/received`}
                    className="submitforapprov"
                    onClick={submitForApproval}
                  >
                    Submit for Approval
                  </Link>
                ) : null}
              </div>
            ) : null}

            <p className="error" ref={loadTotalRef}></p>
            <p className="error" ref={loadvideoRef}></p>
            <p className="error" ref={loadwidthRef}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadVideo;
