import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import L from "leaflet";
import Icon from "../../images/location-pin-svgrepo-com.svg";

const customIcon = L.icon({
  iconUrl: Icon, // Provide a URL to your icon
  iconSize: [25, 41], // Icon size
  iconAnchor: [12, 41], // Anchor point
  popupAnchor: [0, -41], // Popup position relative to the marker
});
const MapChart = ({ location }) => {
  const pins = [
    { id: 1, position: [24.4539, 54.3773], label: "Abu Dhabi - Capital" },
    { id: 2, position: [25.276987, 55.296249], label: "Dubai" },
    { id: 3, position: [25.401, 55.513], label: "Sharjah" },
    { id: 4, position: [25.3312, 55.5136], label: "Ajman" },
  ];
  console.log(location);
  return (
    <MapContainer
      center={[25.276987, 55.296249]}
      zoom={7}
      style={{ height: "100%", width: "100%" }}
    >
      {/* <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  /> */}
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      {location?.map((pin) => (
        <Marker key={pin?.id} position={[pin?.latitude, pin?.longitude]} icon={customIcon}>
          <Popup>{pin?.location}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapChart;
